import Vue from 'vue';
import App from './App';
import { router } from './router';
import Vant from 'vant';
import 'vant/lib/index.css';
import Vconsole from 'vconsole'

import console from 'console'
// //导入axios
// import Axios from "axios"
// //将axios挂载到原型
// Vue.prototype.axios = Axios

// let vConsole = new Vconsole()
//
// Vue.use(vConsole)

new Vue({
  router,
  el: '#app',
  render: h => h(App)
});


Vue.use(Vant);


Vue.use(console);
