import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/guide'
  },
  {
    name: 'user',
    component: () => import('./view/user'),
    meta: {
      title: '会员中心'
    }
  },
  {
    name: 'guide',
    component: () => import('./view/guide'),
    meta: {
      title: '引导'
    }
  },
  {
    name: 'reg',
    component: () => import('./view/reg'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'login',
    component: () => import('./view/login'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'order',
    component: () => import('./view/order'),
    meta: {
      title: '订单'
    }
  },
  {
    // name: 'orderDetail',
    component: () => import('./view/order/detail/detail'),
    path: '/orderDetail/:orderNo',
    meta: {
      title: '订单详情'
    }
  },
  {
    name: 'cart',
    component: () => import('./view/cart'),
    meta: {
      title: '购物车'
    }
  },
  {
    name: 'wms',
    component: () => import('./view/wms'),
    meta: {
      title: '运单'
    }
  },
  // {
  //   name: 'order',
  //   component: () => import('./view/order'),
  //   meta: {
  //     title: '订单'
  //   }
  // },
  {
    name: 'goods',
    component: () => import('./view/goods'),
    meta: {
      title: '商品详情'
    }
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
